<!-- eslint-disable -->
<template>
  <div>
    <v-data-table :sort-by="ativarFiltragem.item" :sort-desc="ativarFiltragem.desc" :item-class="isDevolucao" single-expand dark
      :custom-filter="dateFilterFunc" :headers="h" :items="dadosFiltrados" :expanded.sync="expanded" item-key="objectId"
      show-expand class="quinta elevation-1 c-table-historico__sellout" :search="search">

      <!-- BARRA DE PROGRESSO E INPUTS DE DATAS -->
      <template v-slot:top>
        <v-progress-linear absolute top :height="8" :active="loopLoading" :indeterminate="loopLoading"
        color="#727272"
          class="pt-1"></v-progress-linear>

        <!-- INPUTS DE DATAS -->
        <v-row align="center" align-content="center">
          <!-- INPUT SELEÇÃO DE DATA INICIO -->
          <v-col cols="12" class="text-center">
            <input type="datetime-local" v-model="dateFilter.start" label="Data inicial" class="c-input-data">
            <v-divider class="mx-4" vertical />
              <!-- INPUT SELEÇÃO DE DATA FIM -->
              <input type="datetime-local" v-model="dateFilter.end" label="Data final" class="c-input-data">
            </v-col>
            <!-- INPUT BUSCADOR -->
          <v-col cols="12" class="mb-4">
            <v-text-field outlined class="mx-3" v-model="search" append-icon="mdi-magnify"
              label="Buscar Histórico" single-line hide-details></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`expanded-item`]="{ headers, item }">

        <td :colspan="headers.length">
          <div v-for="(produto, idx) in item.produtos" :key="idx">
           <v-chip label color="quarta" class="mt-2"> Produto: {{ produto.nomeProduto }} | {{ produto.unidade }} {{ produto.unidade > 1 ? "Unidades" : "Unidade" }} </v-chip> <br />
           <span class="pl-1"> Unidade Medida: {{ produto.unidadeMedida }} | Fator: {{ produto.fator }}</span>
            <v-divider class="ma-2 linha-endereco" />
          </div>
        </td>

      </template>

      <template v-slot:item.dataEnvio="props">
        {{ identificarTipoData2(props.item.dataEnvio) }}
      </template>

      <template v-slot:item.periodo="props">
        {{ formatDataBr(props.item.periodo) }}
      </template>

      <template v-slot:item.dataNf="props">
        {{ formatDataBr(props.item.dataNf) }}
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { urlApi, showError, cofingK } from "@/global";
import axios from "axios";
export default {
  name: "historicoSellOut",
  props: { openHistoricoSell: Boolean },
  components: {},
  data: () => ({
    dateFilter: {
      start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 16),
      end: new Date().toISOString().slice(0, 16)
    },
    loopLoading: false,
    search: "",
    expanded: [],
    dataHistoricoSell: [],
    h: [
      {
        text: "objectId",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
      },
      { align: "center", text: "Empresa", value: "empresa" },
      { align: "center", text: "Cliente", value: "cliente", cellClass: "u-font-12" },
      { align: "center", text: "Nota Fiscal", value: "notaFiscal" },
      { align: "center", text: "CNPJ", value: "cnpj" },
      { align: "center", text: "Cliente Entrega", value: "clienteEntrega", cellClass: "u-font-12 pt-1" },
      { align: "center", text: "CNPJ Entrega", value: "cnpjEntrega" },
      { align: "center", text: "Data do Envio", value: "dataEnvio" },
      { align: "center", text: "Periodo", value: "periodo" },
      { align: "center", text: "Data NF", value: "dataNf" },
      { align: "center", text: "Tipo de Documento", value: "tipoDeDocumento" },
      { align: "center", text: "Produtos", value: "data-table-expand" },
    ],
  }),
  computed: {
    ativarFiltragem() {
      if (this.openHistoricoSell) {
        let sort = {item: 'dataEnvio', desc: true}
        return sort
      } else {
        let sort = {item: [], desc: false}
        return sort
      }
    },
    identificarTipoData2() {
      return data => {
        let dataObjeto;
        let dataFormatada = '';
        let horaFormatada = '';

        if (data.includes('T')) {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;

            const hora = dataObjeto.getHours().toString().padStart(2, '0');
            const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
            const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');

            horaFormatada = `${hora}h:${minutos}min:${segundos}s`;
          }
        } else {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;
          }
        }
        return `${dataFormatada} ${horaFormatada}`.trim();
      }
    },
    identificarTipoData() {
      return data => {
        if (data.includes('T')) {
          // Data com hora
          const dataHora = new Date(data);
          const dia = dataHora.getDate().toString().padStart(2, '0');
          const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
          const ano = dataHora.getFullYear();
          const hora = dataHora.getHours().toString().padStart(2, '0');
          const minutos = dataHora.getMinutes().toString().padStart(2, '0');
          const dataFormatada = `${dia}/${mes}/${ano} Hora: ${hora}:${minutos}min.`;
          return dataFormatada;
        } else {
          // Data apenas
          const dataArray = data.split('-');
          const dia = dataArray[2];
          const mes = dataArray[1];
          const ano = dataArray[0];
          const dataFormatada = `${dia}/${mes}/${ano}`;
          return dataFormatada;
        }
      }
    },
    formatDataBr() {
      return (data => this.dataFormatadaBr(data))
    },
    dadosFiltrados() {
      let dfiltrado = this.dataHistoricoSell.filter(item => {
        if (!this.dateFilter.start || !this.dateFilter.end) {
          return true; // Se não houver filtro de data definido, exibir todos os itens
        }

        const startDate = new Date(this.dateFilter.start);
        const endDate = new Date(this.dateFilter.end);
        const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados


        const isDateMatch = itemDate >= startDate && itemDate <= endDate;

        const isTextMatch = this.search === '' || item.clienteEntrega.toLowerCase().includes(this.search.toLowerCase()) || item.cliente.toLowerCase().includes(this.search.toLowerCase()) || item.empresa.toLowerCase().includes(this.search.toLowerCase());
        return isDateMatch && isTextMatch;
      })
      return dfiltrado
    }
  },
  watch: {
    openHistoricoSell: {
      handler(novo) {
        if (novo) {
          this.listarHistoricoSell();
          this.atualizarDataHoraModelo();
        } else {
          this.dataHistoricoSell = [];
        }
      },
      immediate: true,
    },

  },

  methods: {
    atualizarDataHoraModelo() {
      const agora = new Date();
      agora.setMinutes(agora.getMinutes() - agora.getTimezoneOffset() + 60);
      this.dateFilter.end = agora.toISOString().slice(0, 16);
      this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 16);
    },
    isDevolucao(item) {
      return { 'c-venda-devolvida': item.tipoDeDocumento === "CL" ? true : false }
    },
    dataFormatadaBr(data) {
      const dataString = data;

      // Dividir a string da data em partes separadas
      const [ano, mes, dia] = dataString.split('-');

      // Formatar a data no formato desejado
      return `${dia}/${mes}/${ano}`;
    },
    dateFilterFunc(value, search, item) {
      if (!this.dateFilter.start || !this.dateFilter.end) {
        return true; // Se não houver filtro de data definido, exibir todos os itens
      }

      const startDate = new Date(this.dateFilter.start);
      const endDate = new Date(this.dateFilter.end);
      const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados

      const isDateMatch = itemDate >= startDate && itemDate <= endDate;

      const isTextMatch = search === '' || item.clienteEntrega.toLowerCase().includes(search.toLowerCase()) || item.cliente.toLowerCase().includes(search.toLowerCase()) || item.empresa.toLowerCase().includes(search.toLowerCase());

      return isDateMatch && isTextMatch;

    },
    listarHistoricoSell() {
      this.loopLoading = true
      axios
        .post(
          `${urlApi}/listar-historico-sellout`,
          {},
          {
            headers: cofingK,
          }
        )
        .then((res) => {
          this.dataHistoricoSell = res.data.result;
          this.$toasted.global.defaultSuccess();
          this.loopLoading = false
        })
        .catch(err => {
          showError(err)
          this.loopLoading = false
        });
    },
  },
  created() {
  },
  beforeMount() { },
};
</script>

<style>
.c-table-historico__sellout thead.v-data-table-header {
    background: #313131;
}
.c-table-historico__sellout th i {
    display: none!important;
}
td.text-start.u-font-12 {
  font-size: 12px !Important;
  padding: 7px !important;
  background: #3331ab;
}

.c-venda-devolvida:hover {
  background: #F44336 !important;
}

.c-venda-devolvida {
  background: red;
}

.c-input-data {
  border: solid 1px white;
  padding: 10px;
  border-radius: 5px;
  background: whitesmoke;
}

.c-table-historico__sellout table td {
  overflow-x: scroll;
  border: solid 1px #727272;
  font-size: 13px !important;
}

.c-table-historico__sellout thead th {
  border: solid 1px #727272;
  padding: 0px 5px !important;
  width: 260px !important;
}
</style>
