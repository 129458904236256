<template>
  <v-row justify="space-between" class="px-10">
    <v-dialog
      v-model="dialogoProduto"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card dark color="primeira">
        <v-toolbar dark color="primeira">
          <h2 class="nona--text">Inventário</h2>
          <v-btn
            color="primeira"
            small
            absolute
            right
            float
            fab
            dark
            @click="dialogoProduto = false"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <tabelaProdutos :dialogoProduto="dialogoProduto"></tabelaProdutos>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import barramento from "@/barramento";
import tabelaProdutos from "./tabelaProdutos";
export default {
  name: "produtos-screen",
  components: {
    tabelaProdutos,
  },
  data() {
    return {
      dialogoProduto: false,
    };
  },
  created() {
    barramento.getOpenInventario(() => this.dialogoProduto = true);
  },
};
</script>

<style>
</style>