<template>
  <v-data-table
  :sort-by="ativarFiltragem.item"
  :sort-desc="ativarFiltragem.desc"
  :headers="h"
  single-expand
  :items="dadosFiltrados"
  :expanded.sync="expanded"
  item-key="objectId"
  show-expand
  class="quinta elevation-1 c-table-historico__inventario">

    <!-- BARRA DE PROGRESSO E INPUTS DE DATAS -->
    <template v-slot:top>
      <v-progress-linear absolute top :height="8" :active="loopLoading" :indeterminate="loopLoading" color="#727272"
        class="pt-1"></v-progress-linear>

      <!-- INPUTS DE DATAS -->
      <v-row align="center" align-content="center">
        <!-- INPUT SELEÇÃO DE DATA INICIO -->
        <v-col cols="12" class="text-center mb-5">
          <input type="datetime-local" v-model="dateFilter.start" label="Data inicial" class="c-input-data"> <v-divider
            class="mx-4" vertical />
          <!-- INPUT SELEÇÃO DE DATA FIM -->
          <input type="datetime-local" v-model="dateFilter.end" label="Data final" class="c-input-data">
        </v-col>
      </v-row>
    </template>

    <template v-slot:[`expanded-item`]="{ headers, item  }">
      <td :colspan="headers.length">
          <div v-for="(produto, idx) in item.produtos" :key="idx">
           <v-chip label color="quarta" class="mt-2"> Produto: {{ produto.nomeProduto }} | {{ produto.unidades }} {{ produto.unidades > 1 ? "Unidades" : "Unidade" }} </v-chip> <br />
           <span class="pl-1"> Fator: {{ produto.fator }}</span>
            <v-divider class="ma-2 linha-endereco" />
          </div>
        </td>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.dataEnvio="props">
      {{ identificarTipoData(props.item.dataEnvio) }}
    </template>

  </v-data-table>
</template>

<script>
import { urlApi, showError, cofingK } from "@/global";
import axios from "axios";
export default {
  name: "HistoricoInventario",
  props: { openHistorico: Boolean },
  components: {},
  data: () => ({
    dateFilter: {
      start: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 16),
      end: new Date().toISOString().slice(0, 16)
    },
    loopLoading: false,
    expanded: [],
    dataHistorico: [],
    h: [
      {
        text: "objectId",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
      },
      { align: "start", text: "Empresa", value: "empresa" },
      { align: "center", text: "Data do Envio", value: "dataEnvio" },
      { align: "center", text: "Periodo", value: "periodo" },
      { align: "center", text: "Dias de Estoque", value: "diasDeEstoque", width: '150px' },
      { align: "center", text: "Produtos", value: "data-table-expand" },
    ],
  }),
  computed: {
    ativarFiltragem() {
      if (this.openHistorico) {
        let sort = {item: 'dataEnvio', desc: true}
        return sort
      } else {
        let sort = {item: [], desc: false}
        return sort
      }
    },
    identificarTipoData() {
      return data => {
        let dataObjeto;
        let dataFormatada = '';
        let horaFormatada = '';

        if (data.includes('T')) {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;

            const hora = dataObjeto.getHours().toString().padStart(2, '0');
            const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
            const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');

            horaFormatada = `às ${hora}h:${minutos}min:${segundos}s`;
          }
        } else {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;
          }
        }
        return `${dataFormatada} ${horaFormatada}`.trim();
      }
    },
    formatDataBr() {
      return (data => this.dataFormatadaBr(data))
    },
    dadosFiltrados() {
      let dfiltrado = this.dataHistorico.filter(item => {
        if (!this.dateFilter.start || !this.dateFilter.end) {
          return true; // Se não houver filtro de data definido, exibir todos os itens
        }

        const startDate = new Date(this.dateFilter.start);
        const endDate = new Date(this.dateFilter.end);
        const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados
        const isDateMatch = itemDate >= startDate && itemDate <= endDate;
        return isDateMatch
      })
      return dfiltrado
    }
  },
  watch: {
    openHistorico: {
      handler(novo) {
        if (novo) {
          this.listarHistorico();
          this.atualizarDataHoraModelo();
        } else {
          this.dataHistorico = []
        }
      },
      immediate: true,
    },
  },
  methods: {
    atualizarDataHoraModelo() {
      const agora = new Date();
      agora.setMinutes(agora.getMinutes() - agora.getTimezoneOffset() + 60);
      this.dateFilter.end = agora.toISOString().slice(0, 16);
      this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 16);
    },
    dataFormatadaBr(data) {
      const dataString = data;

      // Dividir a string da data em partes separadas
      const [ano, mes, dia] = dataString.split('-');

      // Formatar a data no formato desejado
      return `${dia}/${mes}/${ano}`;
    },
    listarHistorico() {
      axios
        .post(
          `${urlApi}/listar-historico-inventario`,
          {},
          {
            headers: cofingK,
          }
        )
        .then((res) => {
          this.dataHistorico = res.data.result;
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
  },
  created() { },
  beforeMount() { },
  mounted() { },
};
</script>

<style>
.c-table-historico__inventario table td {
  overflow-x: scroll;
  border: solid 1px #727272;
  font-size: 13px !Important;
}

.c-table-historico__inventario thead th {
  border: solid 1px #727272;
  padding: 0px 5px !important;
  width: 260px !important;
}
</style>