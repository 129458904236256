import Vue from 'vue'
export const apiMagically = {
    urlsApi: {
        urlImportacao: 'https://web-production-caed.up.railway.app/https://apinestleselloutintegracaodev.magically.com.br/api/Importacao',
        urlInventario: 'https://web-production-caed.up.railway.app/https://apinestleselloutintegracaodev.magically.com.br/api/Inventario'
    },
}
export const userKey = '__Sell_Out__Nutrimix__*WasLar*AVIZVFX*'
/* export const cofingK = {
    "X-Parse-Application-Id": "sK72zP9NwFFCUZ6H3hqBvEQNc3a0hFLTeGk9KQ2l",
    "X-Parse-REST-API-Key": "UNMjNJatYn8ebZI5w05FohDpedm5FL9kQyzZZRq8"
} */
export const cofingK = {
    "X-Parse-Application-Id": "pwR2JCJzYjRSs07KKRskivO9nV2sS5OuZa8bXmvn",
    "X-Parse-REST-API-Key": "KhIA3VnztMbeod7R1YC3aCCW2hHGGynPBzXZPl4G"
}
export const urlApi = 'https://parseapi.back4app.com/functions'
/* export const urlApi = 'http://localhost:1337/parse/functions' */

export function showError(e) {
    if (typeof e === "object") {
        Vue.toasted.global.defaultError({
            msg: e.response.data.error
        })
    } else if (typeof e == 'string') {
        Vue.toasted.global.defaultError({
            msg: e
        })
    } else {
        Vue.toasted.global.defaultError()
    }
}

export default {
    urlApi,
    showError,
    cofingK,
    apiMagically
}